import "antd/dist/antd.css";
import React from 'react';
import { Helmet } from 'react-helmet';
import { hot } from 'react-hot-loader/root';
import { Provider, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Redirect, Switch, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import store from './redux/store';
import Admin from './routes/Admin';
import AuthContainer from './routes/Auth/comps/AuthContainer';
import ForgotPassword from './routes/Auth/comps/ForgotPassword';
import ResetPassword from './routes/Auth/comps/ResetPassword';
import SignIn from './routes/Auth/comps/SignIn';
import SignUp from './routes/Auth/comps/SignUp';
import './static/css/style.scss';
import config from './config/config';
import ProtectedRoute from './components/utilities/protectedRoute';
import PublicRoute from './components/utilities/publicRoute';
import pages from './config/pages';
import NotFound from './container/pages/404';

const { theme } = config;

const ProviderConfig = () => {
  const { rtl, topMenu, darkMode } = useSelector(state => {
    return {
      darkMode: state.ChangeLayoutMode.data,
      rtl: state.ChangeLayoutMode.rtlData,
      topMenu: state.ChangeLayoutMode.topMenu,
    };
  });

  return (
    <>
      <Helmet htmlAttributes={{ lang: config.currentLang.culture, dir: rtl ? 'rtl' : 'ltr' }} />
      <ConfigProvider direction={rtl ? 'rtl' : 'ltr'} locale={config.currentLang.antLang}>
        <ThemeProvider theme={{ ...theme, rtl, topMenu, darkMode }}>
          <Router basename={process.env.PUBLIC_URL}>
            <Switch>
              <PublicRoute
                path={`/${pages.login}`}
                component={() => (
                  <AuthContainer>
                    <SignIn />
                  </AuthContainer>
                )}
              />
              <PublicRoute
                path={`/${pages.signUp}`}
                component={() => (
                  <AuthContainer>
                    <SignUp />
                  </AuthContainer>
                )}
              />
              <PublicRoute
                path={`/${pages.forgotPassword}`}
                component={() => (
                  <AuthContainer>
                    <ForgotPassword />
                  </AuthContainer>
                )}
              />
              <PublicRoute
                path={`/${pages.resetPassword}`}
                component={() => (
                  <AuthContainer>
                    <ResetPassword />
                  </AuthContainer>
                )}
              />
              <ProtectedRoute path="/" component={Admin} />
              <Redirect to={`/${pages.login}`} />
            </Switch>
          </Router>
        </ThemeProvider>
      </ConfigProvider>
    </>
  );
};

function App() {
  return (
    <Provider store={store}>
      <ProviderConfig />
    </Provider>
  );
}

export default hot(App);
